import '../../service/domain/system.js';
(function () {
  'use strict';

  const component = { name: 'lindaAppCurrentVersion' };

  controller.$inject = ['$scope', 'system'];

  function controller($scope, _system) {
    $scope.device = {};

    $scope.preloader = true;
    _system.mobile.currentVersion().then(
      (answer) => {
        $scope.device = answer.result;
        $scope.preloader = false;
      },
      (answer) => {
        $scope.preloader = false;
      }
    );
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
