import '../../../service/domain/game.js';
(function () {
  'use strict';

  const component = { name: 'lindaWidgetGameItem' };

  controller.$inject = ['$scope', 'game'];

  function controller($scope, _game) {
    $scope.collection = [];
    $scope.content = {};
    const config = {};

    this.$onInit = function () {
      $scope.content = this.content;
      config.category = this.category;
      config.count = this.count;
      config.brand = this.provider;
      config.out = this.fields;
      config.random = this.random;
      config.tournament = this.tournament;
      getCollection(config);
    };

    function getCollection(options) {
      $scope.preloader = true;
      _game.collection(options).then(
        (answer) => {
          $scope.collection = answer.result;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    }
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      category: '<',
      provider: '<',
      fields: '<',
      tournament: '<',
      random: '<',
      count: '<',
      content: '<',
    },
  });
})();
