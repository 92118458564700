(function () {
  'use strict';

  const component = { name: 'lindaDemoGameFooter' };

  controller.$inject = ['$scope'];

  function controller($scope) {
    $scope.game = {};

    this.$onChanges = function () {
      $scope.game = this.game;
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      game: '<',
    },
  });
})();
