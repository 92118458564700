import '../../../service/domain/game.js';
import '../../../service/rx/keyup$.js';
import {filter, debounceTime} from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaWidgetSearch' };

  const classLib = {
    full: 'linda-has-games', // клас з'являється на блоці, коли елементи є у collection
    empty: 'linda-not-found', // коли результат пошуку пустий
  };

  controller.$inject = ['$scope', 'game', 'keyup$', '$element'];

  function controller($scope, _game, _keyup$, $element) {
    $scope.result = [];
    $scope.preloader = false;

    _keyup$
      .pipe(
        filter((e) => e.target.hasAttribute('search-input')),
        debounceTime(300)
      )
      .subscribe((data) => {
        if (data.target.value && data.target.value.length >= 2) {
          $scope.preloader = true;

          const options = {
            search: data.target.value,
            count: 6,
          };

          _game.collection(options).then(
            function (answer) {
              $scope.result = answer.result;
              $element[0].classList.toggle(classLib.full, answer.result.length > 0);
              $element[0].classList.toggle(classLib.empty, answer.result.length === 0);
              $scope.preloader = false;
            },
            function (answer) {
              $scope.preloader = false;
            }
          );
        } else {
          $element[0].classList.remove(classLib.full);
          $element[0].classList.remove(classLib.empty);

          $scope.result = [];
          $scope.$apply();
        }
      });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
