import '../../service/domain/game.js';
import '../../service/rx/scroll$.js';
import '../../service/rx/resize$.js';
(function () {
  'use strict';

  const component = { name: 'lindaGameBox' };

  controller.$inject = ['$scope', 'game', '$element', 'scroll$', 'resize$'];

  function controller($scope, _game, $element, _scroll$, _resize$) {
    $scope.collection = [];
    $scope.preloader = false;
    $scope.content = {};
    $scope.category = null;
    $scope.provider = null;
    $scope.lastPage = false;
    $scope.infiniteScroll = false;

    let page = 0;
    let loading = false;
    const config = {};
    let stream;

    function getCollection(options) {
      if (!loading) {
        loading = true;
        page++;
        options.page = page;
        $scope.preloader = true;
        _game.collection(options).then(
          function (answer) {
            $scope.collection = $scope.collection.concat(answer.result);
            $scope.lastPage = answer.page >= answer.total_pages;
            $scope.preloader = false;
            loading = false;
          },
          function (answer) {
            $scope.collection = [];
            $scope.preloader = false;
            loading = false;
          }
        );
      }
    }

    function infiniteScrollInit(delta) {
      let clientHeight = document.documentElement.clientHeight,
        clientWidth = document.documentElement.clientWidth,
        blockRect = $element[0].getBoundingClientRect();

      function renewResolution() {
        clientHeight = document.documentElement.clientHeight;
        clientWidth = document.documentElement.clientWidth;
        blockRect = $element[0].getBoundingClientRect();

        //-if( blockRect.top >= 0 && blockRect.bottom <= clientHeight + delta * 1 ){
        if (blockRect.bottom <= clientHeight + delta * 1) {
          $scope.nextPage();
        }
      }

      stream = _scroll$.merge(_resize$).subscribe((e) => {
        if (!$scope.lastPage) renewResolution();
      });
    }

    this.$onDestroy = () => {
      if (stream && typeof stream.unsubscribe === 'function') stream.unsubscribe();
    };

    //this.$onInit = function(){
    this.$onChanges = function () {
      page = 0;

      $scope.collection = [];

      $scope.content = this.content;
      $scope.category = this.category;
      $scope.provider = this.provider;
      //-$scope.infiniteScroll = this.infiniteScroll || false;
      config.category = this.category;
      config.count = this.count;
      config.brand = this.provider;
      config.out = this.fields;

      config.random = this.random;

      config.tournament = this.tournament;

      getCollection(config);

      const deltaY = parseInt(this.infiniteScroll);

      if (deltaY >= 0) {
        infiniteScrollInit(deltaY);
      }
    };

    $scope.nextPage = function () {
      getCollection(config);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      category: '<',
      count: '<',
      content: '<',
      provider: '<',
      fields: '<',
      tournament: '<',
      random: '<',
      infiniteScroll: '<',
    },
  });
})();
