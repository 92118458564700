(function () {
  'use strict';

  const component = { name: 'lindaLanding' };

  controller.$inject = [];

  function controller() {}

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
