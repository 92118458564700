import '../../service/domain/promo.js';
(function () {
  'use strict';

  const component = { name: 'lindaPromotions' };

  controller.$inject = ['$scope', 'promo'];

  function controller($scope, _promo) {
    $scope.promotions = [];
    $scope.preloader = true;

    _promo.list().then(
      function (answer) {
        $scope.promotions = answer.result;
        $scope.preloader = false;
      },
      function (answer) {
        $scope.preloader = false;
      }
    );
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
