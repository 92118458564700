import '../../../service/domain/history.js';
import '../../../service/domain/withdraw.js';
(function () {
  'use strict';

  const component = { name: 'lindaCashboxHistoryWithdraw' };

  controller.$inject = ['$scope', 'history', 'withdraw'];

  function controller($scope, _history, _withdraw) {
    $scope.preloader = false;
    $scope.collection = [];

    $scope.filter = {
      type: 'withdraw',
      period: 'week',
      datefrom: _history.getFormattedYesterday(),
      dateto: _history.getFormattedToday(),
    };

    $scope.cancel = (id) => {
      $scope.preloader = true;
      _withdraw.cancel({ alias: id }).then(
        (answer) => {
          $scope.collection = $scope.collection.filter((item) => {
            return item.id !== id;
          });
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    };

    $scope.renew = () => {
      const opt = {
        params: $scope.filter,
        endpoint: 'payment',
      };

      $scope.preloader = true;
      _history.collection(opt).then(
        (answer) => {
          $scope.preloader = false;
          $scope.collection = answer.result;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    };

    $scope.renew();
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
