(function () {
    'use strict';

    const directive = { name: 'iconBonusInfo' };

    controller.$inject = [];

    function controller() {
        function link(scope, element, attrs) {
            const icon = element[0];

            const bonusTooltip = icon.closest('.bonus-tooltip');
            const hiddenDiv = bonusTooltip ? bonusTooltip.querySelector('.bonus-tooltip__area') : null;

            if (hiddenDiv) {
                element.on('click', function (event) {
                    event.stopPropagation();
                    if (hiddenDiv.style.display === 'block') {
                        hiddenDiv.style.display = 'none';
                    } else {
                        hiddenDiv.style.display = 'block';
                    }
                });

                angular.element(document).on('click', function (event) {
                    if (!icon.contains(event.target) && !hiddenDiv.contains(event.target)) {
                        hiddenDiv.style.display = 'none';
                    }
                });
            }
        }

        return {
            restrict: 'A',
            link,
        };
    }

    app.directive(directive.name, controller);
})();
