import '../../service/domain/tournament.js';
(function () {
  'use strict';

  const component = { name: 'lindaTournament' };

  controller.$inject = ['$scope', 'tournament', '$state'];

  function controller($scope, _tournament, $state) {
    const tournamentName = $state.params.name;
    $scope.tournament = {
      name: tournamentName,
    };
    $scope.preloader = false;

    function getTournament(opt) {
      $scope.preloader = true;
      _tournament.item(opt).then(
        function (answer) {
          $scope.tournament = answer.result;
          $scope.preloader = false;
        },
        function (answer) {
          $scope.preloader = false;
        }
      );
    }

    this.$onChanges = () => {
      getTournament({
        name: this.name,
        params: {},
      });
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      name: '<',
    },
  });
})();
