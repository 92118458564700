import '../../service/domain/bonus.js';
import '../../service/rx/bonus$.js';
import '../../service/domain/user.js';
(function () {
  'use strict';

  const component = { name: 'lindaDashboard' };

  controller.$inject = ['$scope', 'bonus', 'bonus$', 'user'];

  function controller($scope, _bonus, _bonus$, _user) {
    $scope.bonus = {};
    $scope.freespin = {};

    _bonus$.subscribe((data) => {
      let bonus = _user.bonus.find((item) => {
        return item.type === 'casino' && item.status === 'new';
      });

      let freespin = _user.bonus.find((item) => {
        return item.type === 'freespin' && item.status === 'new';
      });

      $scope.bonus = typeof bonus !== 'undefined' ? bonus : {};
      $scope.freespin = typeof freespin !== 'undefined' ? freespin : {};
    });

    $scope.preloader = true;
    _bonus.list().then(
      (answer) => {
        $scope.preloader = false;
      },
      (answer) => {
        $scope.preloader = false;
      }
    );
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
