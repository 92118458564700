(function () {

    'use strict';

    const directive = {name: 'alfCheckedWb'};

    controller.$inject = [];

    function controller() {

        const localStorageKey = 'alfCheckedWb'

        function link(scope, element, attrs) {
            scope.$watchCollection(
              "collection",
              function () {
                  if (scope.collection?.length && scope.collection[0].trigger_codes && !scope.noBonus) {
                      scope.formData.trigger_codes = scope.collection[0].trigger_codes;
                  }
                }
            );

            const raw = window.localStorage.getItem(localStorageKey) || false;
            scope.noBonus = raw && raw !== 'false';
            scope.setNoBonus = (value) => {
                scope.noBonus = value;

                window.localStorage.setItem(localStorageKey, value);
            }
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive(directive.name, controller);

})();
