(function () {
  'use strict';

  const component = { name: 'lindaTimer' };

  controller.$inject = ['$scope', '$interval'];

  function controller($scope, $interval) {
    let time = 0;

    $scope.timer = time;

    const timer = $interval(() => {
      if (time > 0) {
        time--;
        $scope.timer = time;
      }
    }, 1000);

    this.$onChanges = () => {
      time = Math.round(this.start / 1000);
    };

    this.$onDestroy = () => {
      $interval.cancel(timer);
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      start: '<',
    },
  });
})();
