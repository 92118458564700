import '../../service/domain/system.js';
import { Subject } from 'rxjs';
(function () {
  'use strict';

  const component = { name: 'lindaHeroSlider' };

  cmpCtrl.$inject = ['$scope', 'system'];

  function cmpCtrl($scope, _system) {
    $scope.preloader = false;
    $scope.loaded = false;
    $scope.avatars = [];
    const characterSubject$ = new Subject();

    $scope.setCurrent = function (event, slick, currentSlide, nextSlide) {
      $scope.$ctrl.hero = $scope.avatars[nextSlide];
    };

    characterSubject$.subscribe({
      next: (id) => getAvatars(id),
    });

    function getAvatars(id) {
      $scope.preloader = true;
      _system
        .avatars()
        .then((answer) => {
          $scope.avatars = answer.result;
          $scope.$ctrl.hero = answer.result[id];
          $scope.loaded = true;
          $scope.preloader = false;
        })
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = () => {
      if (angular.isObject($scope.sliderSettings)) {
        const character = parseInt($scope.$ctrl.initialSlide, 10);
        $scope.sliderSettings.initialSlide = character;
        characterSubject$.next(character);
        return;
      }
      characterSubject$.next(0);
    };

    this.$onDestroy = () => {
      characterSubject$.unsubscribe();
    };
  }

  app.component(component.name, {
    controller: cmpCtrl,
    template: app.getTU(component.name),
    bindings: {
      hero: '=',
      initialSlide: '<',
    },
  });
})();
