(function(){

	'use strict';

	const directive = {
		name: 'addClassRegistrationPage'
	};

	controller.$inject = [];

	function controller( ){

		function link( scope, element, attrs, ctrl ){
			document.getElementsByClassName('page-body-footer__footer')[0].classList.add('is-hide')
			document.getElementsByClassName('page-body__container')[0].classList.add('reg-page')
			let watcher = function(){
				document.getElementsByClassName('page-body-footer__footer')[0].classList.remove('is-hide')
				document.getElementsByClassName('page-body__container')[0].classList.remove('reg-page')
			};

			scope.$on('$destroy', watcher);
		}

		return {
			restrict: 'A',
			link,
		};
	}

	app.directive( directive.name, controller );

}());