import '../../service/domain/http.js';
(function () {
  'use strict';

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    return {
      /*

                  item( options ){

                      const defer = $q.defer(),
                          alias = {
                              name: 'infoblock',
                              param: options.name
                          },
                          config = {
                              cache: true,
                              meta: {
                                  type: 'api',
                                  alias
                              }
                          };

                      _http.get( alias, config ).then(
                          answer => {
                              defer.resolve( answer.data );
                          },
                          answer => {
                              defer.reject( answer.data );
                          },
                      );

                      return defer.promise;

                  },

      */

      reward(options) {
        const defer = $q.defer(),
          alias = 'infoblockReward',
          config = {
            cache: true,
            params: options,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      win(options) {
        const defer = $q.defer(),
          alias = 'infoblockWin',
          config = {
            cache: true,
            params: options,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }

  app.factory('infoblock', controller);
})();
