import '../../service/domain/banner.js';
(function () {
  'use strict';

  const component = { name: 'lindaMainBanner' };

  controller.$inject = ['$scope', 'banner'];

  function controller($scope, _banner) {
    $scope.preloader = false;
    $scope.banner = {};

    function getBanner() {
      $scope.preloader = true;
      _banner.collection({ category: 'banner', place: 'mainpage', out: 'text,title' }).then(
        (answer) => {
          $scope.banner = answer.result[0];
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    }

    getBanner();
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
