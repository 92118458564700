import { $_PGW } from "./pgw.mjs";
var $_CONFIG = $_CONFIG || {};

$_CONFIG.scripts = $_CONFIG.scripts || [];                          // масив скриптів, які будуть додані в дом

$_CONFIG.domain = 'https://alfcasino.devv2.space';                                               // домен апі (рекомендовано залишати пустим)
$_CONFIG.siteDomain = 'https://alfcasino.com';
$_CONFIG.siteName = 'alf';

$_CONFIG.cdn = '/joxi';                       // домен сервера cdn (поки один на всі проекти)

$_CONFIG.apiVersion = 'v2';                                         // версія апі (якщо не вказано, використовується v1)

$_CONFIG.mid = 19;                                                 // id пікселя для інкам. ВАЖЛИВО! В кожного сайта своє
$_CONFIG.mobile = true;

$_CONFIG.redirectToNotFound = false;

$_CONFIG.libphonenumber = true;
$_CONFIG.lottielib = true;
$_CONFIG.pgw = $_PGW;

$_CONFIG.googleAnalytics = { appId: 'UA-121801220-1', trackAs: 'AlfCasino' }; // added googleAnalytics

$_CONFIG.sentry = {
  enabled: true,
  dsn: 'https://d4cadb214ab345a6943e820342a2ae18@sentry.gosystem.io/80',
  project: 'm_alf_casino',
};

$_CONFIG.places = {
    banner: {
        registration: `'registration_welcome_banner'`
    }
};

$_CONFIG.scss = {                                                           // об'єкт, який передається в scss при компіляції
    test: 'test'
};

$_CONFIG.symplify = true;


export {$_CONFIG};
