import '../../../service/domain/account.js';
import '../../../service/domain/user.js';
import '../../../service/domain/system.js';
(function () {
  'use strict';

  const component = { name: 'lindaProfileForm' };

  controller.$inject = ['$scope', 'account', 'user', 'system'];

  function controller($scope, _account, _user, _system) {
    $scope.preloader = false;
    $scope.countries = [];
    $scope.formValue = {
      email: _user.profile.email,
      phone: _user.profile.phone,
    };

    $scope.formData = {
      name: _user.profile.profile.name,
      surname: _user.profile.profile.surname,
      //            phone: _user.profile.phone,
      birthday: _user.profile.profile.birthday,
      gender: _user.profile.profile.gender,
      country: _user.profile.country,
      city: _user.profile.profile.city,
      address: _user.profile.profile.address,
      postcode: _user.profile.profile.postcode,
    };

    $scope.preloader = true;
    _system.countries().then(
      (answer) => {
        $scope.countries = answer.result;
        $scope.preloader = false;
      },
      (answer) => {
        $scope.preloader = false;
      }
    );

    $scope.submit = (form) => {
      if (form.$valid) {
        $scope.preloader = true;
        _account.update($scope.formData).then(
          (answer) => {
            $scope.preloader = false;
          },
          (answer) => {
            $scope.preloader = false;
          }
        );
      } else {
        angular.forEach(form.$error, (type) => {
          angular.forEach(type, (field) => {
            field.$setTouched();
          });
        });
      }
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
