import '../../../service/domain/bonus.js';
(function () {
  'use strict';

  const component = { name: 'lindaProfileStaff' };

  controller.$inject = ['$scope', 'bonus'];

  function controller($scope, _bonus) {}

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
