import '../../service/domain/content-buffer.js';
import '../../service/pgw.js';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'pgwWidget' };

  controller.$inject = ['pgw', 'contentBuffer', '$scope', '$document'];

  function controller(_pgw, _contentBuffer, $scope, $document) {
    $scope.preloader = true;

    const destroy$ = new Subject();
    const { action, amount } = _contentBuffer.read('popup');
    const root = $document[0].querySelector(':root');

    const setStyles = (rules) => Object.entries(rules).forEach(([key, value]) => root.style.setProperty(key, value));
    if ($_CONFIG.pgw?.cssVars) {
      setStyles($_CONFIG.pgw.cssVars);
    }

    _pgw
      .cashboxInit(action, amount)
      .pipe(
        filter((value) => value),
        takeUntil(destroy$)
      )
      .subscribe(() => {
        $scope.preloader = false;
      });

    this.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
