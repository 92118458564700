(function(){

	'use strict';

	// this directive used for watching all inputs in the form and send formData to backabd after blur event

	const directive = { name: 'saveDepAmount' };

	controller.$inject = [  ];

	function controller(){

		const localStorageKey = 'saveDepAmount'

		function link( scope, element, attrs ){
			scope.activeTab = window.localStorage.getItem(localStorageKey) || 'amount';
			scope.selectAmount = (value) => {
				scope.activeTab = value;
				scope.amount = value;

				window.localStorage.setItem(localStorageKey, value)
			}
		}

		return {
			restrict: 'A',
			link,
		};
	}

	app.directive( directive.name, controller );


})();
