const $_PGW = {
  enabled: true,
  layoutMode: 'column',
  cssVars: {
    '--pgw-font-family': 'Montserrat',
    '--pgw-background-primary': 'transparent',
    '--pgw-surface-primary': '#ffffff',
    '--pgw-surface-secondary': '#f6f6f9',
    '--pgw-surface-secondaryHover': '#f0f0f4',
    '--pgw-surface-accent': '#fffae6',
    '--pgw-surface-accentHover': '#fff4c9',
    '--pgw-surface-buttonPrimary': '#09af48',
    '--pgw-surface-buttonPrimaryHover': '#66e38c',
    '--pgw-surface-buttonPrimaryPress': '#078c3a',
    '--pgw-surface-buttonPrimaryDisabled': '#80ec9f',
    '--pgw-surface-buttonSecondary': '#9a85cd',
    '--pgw-surface-buttonSecondaryHover': '#9a85cd',
    '--pgw-surface-buttonSecondaryPress': '#7b6aa4',
    '--pgw-surface-buttonSecondaryDisabled': '#d3c6f4',
    '--pgw-surface-buttonTertiary': '#f0f0f4',
    '--pgw-surface-buttonTertiaryHover': '#e2e2e9',
    '--pgw-surface-buttonTertiaryPress': '#e2e2e9',
    '--pgw-surface-buttonTertiaryDisabled': '#e2e2e9',
    '--pgw-surface-inputPrimary': '#f6f6f9',
    '--pgw-surface-inputSecondary': '#ffffff',
    '--pgw-surface-inputTertiary': '#f0f0f4',
    '--pgw-surface-tab': '#f0f0f4',
    '--pgw-surface-tabHover': '#bfadea',
    '--pgw-surface-tabPress': '#ac98dd',
    '--pgw-surface-negative': '#e76a88',
    '--pgw-surface-positiveAlpha': '#25a8201a',
    '--pgw-surface-skeleton': '#f6f6f926',
    '--pgw-text-primary': '#131325',
    '--pgw-text-secondary': '#646487',
    '--pgw-text-tertiary': '#c5c5d3',
    '--pgw-text-buttonPrimary': '#ffffff',
    '--pgw-text-buttonPrimaryDisabled': '#f0f0f4',
    '--pgw-text-buttonSecondary': '#ffffff',
    '--pgw-text-buttonSecondaryDisabled': '#f0f0f4',
    '--pgw-text-positive': '#24a820',
    '--pgw-text-warning': '#f69230',
    '--pgw-text-negative': '#e76a88',
    '--pgw-text-constant': '#ffffff',
    '--pgw-icon-primary': '#131325',
    '--pgw-icon-secondary': '#646487',
    '--pgw-icon-tertiary': '#c5c5d3',
    '--pgw-icon-buttonPrimary': '#ffffff',
    '--pgw-icon-buttonPrimaryDisabled': '#d5e3f3',
    '--pgw-icon-positive': '#24a820',
    '--pgw-icon-warning': '#f69230',
    '--pgw-icon-negative': '#e76a88',
    '--pgw-stroke-primary': '#f0f0f4',
    '--pgw-stroke-secondary': '#e2e2e9',
    '--pgw-stroke-inputPrimary': '#f0f0f4',
    '--pgw-stroke-inputSecondary': '#e2e2e9',
    '--pgw-stroke-accent': '#ffeeac',
    '--pgw-stroke-accentHover': '#ffe373',
    '--pgw-stroke-positive': '#24a820',
    '--pgw-stroke-positiveAlpha': '#24a8204d',
    '--pgw-stroke-warning': '#f69230',
    '--pgw-stroke-negative': '#e76a88',
    '--pgw-divider-primary': '#f0f0f4',

    '--pgw-border-radius-s': '4px',
    '--pgw-border-radius-m': '8px',
    '--pgw-border-radius-l': '12px',
    '--pgw-border-radius-xl': '16px',
  },
};
export { $_PGW };
