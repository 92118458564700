(function(){

	'use strict';

	const directive = { name: 'scrollToTopWindow' };

	controller.$inject = [ ];

	function controller(){
		function link( scope, element ) {
			element.on( 'tap', () => setTimeout(
				function() {
					$( window ).scrollTop(0)
				}
				,100));
		}

		return {
			restrict: 'A',
			link,
		};
	}

	app.directive( directive.name, controller );


})();
