import '../../service/domain/icon.js';
(function () {
  'use strict';

  const component = { name: 'lindaIconBox' };

  controller.$inject = ['$scope', 'icon'];

  function controller($scope, _icon) {
    $scope.collection = [];
    $scope.content = {};
    $scope.preloader = false;
    const config = {};

    function getCollection(options) {
      $scope.preloader = true;
      _icon.collection(options).then(
        (answer) => {
          $scope.collection = answer.result;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    }

    this.$onInit = function () {
      $scope.content = this.content;
      config.category = this.category;
      config.count = this.count;
      config.out = this.fields;

      getCollection(config);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      category: '<',
      content: '<',
      count: '<',
      out: '<',
    },
  });
})();
