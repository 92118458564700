import '../../service/domain/game.js';
import '../../service/domain/promo.js';
import '../../service/domain/infoblock.js';
(function () {
  'use strict';

  const component = { name: 'lindaLandingDiscover' };

  controller.$inject = ['$scope', 'game', 'promo', 'infoblock'];

  function controller($scope, _game, _promo, _infoblock) {
    $scope.slot1 = {};
    $scope.slot2 = {};
    $scope.slot3 = {};
    $scope.slot4 = {};
    $scope.slot5 = {};
    $scope.slot6 = {};
    $scope.slot7 = {};
    $scope.slot8 = {};

    _game
      .collection({
        random: true,
        category: 'main-new-game',
        count: 1,
        out: 'promo_image=image,alias,demo,name',
      })
      .then(
        (answer) => {
          $scope.slot1 = answer.result[0];
        },
        (answer) => {}
      );

    _infoblock.reward({}).then(
      (answer) => {
        $scope.slot2 = answer.result;
      },
      (answer) => {}
    );

    _infoblock.win({ type: 'latest' }).then(
      (answer) => {
        $scope.slot3 = answer.result;
      },
      (answer) => {}
    );

    _promo
      .list({
        search: 'welcome_bonus',
        out: 'title,name,text,terms',
      })
      .then(
        (answer) => {
          $scope.slot4 = answer.result[0];
        },
        (answer) => {}
      );

    _infoblock.win({ type: 'last' }).then(
      (answer) => {
        $scope.slot5 = answer.result;
      },
      (answer) => {}
    );

    _game
      .collection({
        random: true,
        category: 'main-current-jackpots',
        count: 1,
        out: 'promo_image=image,alias,demo,name',
      })
      .then(
        (answer) => {
          $scope.slot6 = answer.result[0];
        },
        (answer) => {}
      );

    _game
      .collection({
        random: true,
        category: 'main-most-popular',
        count: 1,
        out: 'promo_image=image,alias,demo,name',
      })
      .then(
        (answer) => {
          $scope.slot7 = answer.result[0];
        },
        (answer) => {}
      );

    _infoblock.win({ type: 'month' }).then(
      (answer) => {
        $scope.slot8 = answer.result;
      },
      (answer) => {}
    );
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
