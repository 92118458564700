import '../../../service/helper.js';
(function () {
  'use strict';

  const component = { name: 'lindaCashboxBalance' };

  controller.$inject = ['$scope', 'helper'];

  function controller($scope, _helper) {
    $scope.preloader = false;

    $scope.refresh = _helper.account.refresh.bind($scope);

    $scope.cancel = _helper.bonus.cancel.bind($scope, null);
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
