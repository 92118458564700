import '../../../service/domain/collection.js'
(function () {
  controller.$inject = ['$scope', 'collection'];

  function controller($scope, _collection) {
    $scope.preloader = false;
    $scope.collection = {};
    let collectionAlias;

    function getCollection() {
      $scope.preloader = true;
      _collection
        .list()
        .then((answer) => {
          $scope.collection = answer.result.find((collection) => collection.alias === collectionAlias);
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = () => {
      collectionAlias = this.alias || 'categories';

      getCollection();
    };
  }

  new Controller('gameCategoriesCollection', controller, { alias: '<' });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanGameCategoriesCollection${i}`, 'gameCategoriesCollection');
  });
})();
