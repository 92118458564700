import '../../service/domain/account.js';
(function () {
  'use strict';

  const component = { name: 'lindaAutobonusBanner' };

  controller.$inject = ['$scope', 'account'];

  function controller($scope, _account) {
    $scope.preloader = false;
    $scope.banner = {};

    function getBonuses() {
      $scope.preloader = true;
      _account.autobonuses().then(
        (answer) => {
          $scope.banner = answer.result;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    }

    getBonuses();
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
