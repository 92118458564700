(function () {
  'use strict';

  const component = { name: 'lindaLandingHero' };

  controller.$inject = ['$scope'];

  function controller($scope) {
    $scope.current = 0;
    $scope.setCurrent = function (event, slick, currentSlide, nextSlide) {
      $scope.current = nextSlide;
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
