import '../../service/domain/vip.js';
(function () {
  'use strict';

  const component = { name: 'lindaHeroPopup' };

  controller.$inject = ['$scope', 'vip'];

  function controller($scope, _vip) {
    $scope.collection = [];
    $scope.preloader = true;
    _vip.collection().then(
      (answer) => {
        $scope.collection = answer.result;
        $scope.preloader = false;
      },
      (answer) => {
        $scope.preloader = false;
      }
    );
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
